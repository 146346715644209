export const GlobalVariable = Object({
    
       //SERVICE_API_URL: 'http://gymapi.express-engg.com/api/', //For Server

    // BASE_API_URL:'http://localhost:58427/Content/',
    //BASE_API_URL:'http://localhost:58427/ProfilePhoto/',


      // BASE_API_URL:'http://localhost:58427/ProfilePhoto/',
      // Instructor_Profile_URL: 'http://localhost:58427/Instructor/ProfilePhoto/', 
      // SERVICE_API_URL:'http://localhost:58427/api/', //For Local host

    Instructor_Profile_URL: 'https://gymapi.sivtpune.com/Instructor/ProfilePhoto/',
    BASE_API_URL:'https://gymapi.sivtpune.com/ProfilePhoto/',
    SERVICE_API_URL: 'https://gymapi.sivtpune.com/api/',
    // SERVICE_API_URL: 'http://localhost:58427/api/',

    UserName: 'orgasatva@gmail.com',

    Mobile: '',
    Address: '',
    CustomerId: 0,
    UserId: 0,
    AppVersion:1,
    SId: '',
    Role: '',
    Id: 0,
    MemberId: 0,
});
export class uses{
    flag: boolean;
}



